import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { constants } from "../../utils/constants";
import ImageToggleButton from "../ImageToggleButton";
import { calculatePayout, numberWithCommas, parseValue } from "../side-bets/helpers";
import InsuranceProgressBar from "./InsuranceProgressBar";

const Images = {
    STREAK2: 'https://i.imgur.com/05r6Dl1.png',
    FLIP2: 'https://i.imgur.com/4ZmCHwM.png',
    FLIP3: 'https://i.imgur.com/EPMpmCl.png',
    SOL3: 'https://i.imgur.com/XKD3au1.png',
    SOL4: 'https://i.imgur.com/rS8KeYn.png',
    SOL5: 'https://i.imgur.com/JTK9xby.png',
    SOL6: 'https://i.imgur.com/Efaq1O0.png',
    SOL8: 'https://i.imgur.com/qf9WRFO.png',
    SOL10: 'https://i.imgur.com/2xeW6qb.png',
    ACTIVATE_INSURANCE: 'https://i.imgur.com/s9GNnhl.png'
};

const suffixes = [2, 3];
const prefixOptions = ["L", "W", "H", "T"];

const InsuranceSelectionMenu = ({
    choice,
    setChoice,
    whaleMode,
    amount,
    setAmount,
    onSubmit,
    toggleWhaleMode
}) => {
    const incrementChoice = (currentChoice) => {
        const prefix = currentChoice.charAt(0);
        const currentSuffix = parseInt(currentChoice.slice(1), 10);
        const currentIndex = suffixes.indexOf(currentSuffix);
        const nextIndex = (currentIndex + 1) % suffixes.length;
        return `${prefix}${suffixes[nextIndex]}`;
    };

    const togglePrefix = () => {
        const currentPrefix = choice.charAt(0);
        const currentIndex = prefixOptions.indexOf(currentPrefix);
        const nextIndex = (currentIndex + 1) % prefixOptions.length;
        const newPrefix = prefixOptions[nextIndex];
        return `${newPrefix}${parseInt(choice.slice(1), 10)}`;
    };

    const handleChoice = () => {
        setChoice(incrementChoice(choice));
    };

    const handlePrefixToggle = () => {
        setChoice(togglePrefix());
    };

    const renderTooltip = (props, text) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    return (<>


        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={
                <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                    <div className="card">
                        <div className="card-body">
                            <h6>
                                Insure Your Next Flips
                            </h6>
                            If you buy insurance for a {choice.includes("2") ? '2' : '3'} {choice.includes("L") ? 'loss' : (choice.includes("W") ? 'win' : choice.includes("H") ? "heads" : "tails")} streak, you will earn {choice.includes("2") ? '4' : '8'}x your insurance amount if you {choice.includes("L") ? 'lose' : (choice.includes("W") ? 'win' : choice.includes("H") ? 'hit heads' : 'hit tails')} the next {choice.includes("2") ? '2' : '3'} flips in a row.
                        </div>
                    </div>
                </Popover>}
        >
            <div className="w-100 d-flex justify-content-center">
                <h4 className="mt-2 mb-1 d-inline-flex align-items-center">
                    IF YOU&nbsp;
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => renderTooltip(props, "Click to toggle condition")}
                    >
                        <button
                            className="btn btn-dark btn-sm d-flex align-items-center"
                            onClick={handlePrefixToggle}
                        >
                            {choice.includes("L") ? 'LOSE'
                                : choice.includes("W") ? 'WIN'
                                    : choice.includes("H") ? 'HEADS'
                                        : 'TAILS'}
                            <i className="ms-2 fas fa-exchange-alt"></i>
                        </button>
                    </OverlayTrigger>
                    &nbsp;A&nbsp;
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => renderTooltip(props, "Click to change streak")}
                    >
                        <button
                            className="btn btn-dark btn-sm d-flex align-items-center"
                            onClick={handleChoice}
                        >
                            {parseInt(choice?.slice(1), 10)}-STREAK
                            <i className="ms-2 fas fa-arrow-right"></i>
                        </button>
                    </OverlayTrigger>
                    <span className="ms-1 fas fa-circle fa-info-circle fa-2xs text-secondary"></span>
                </h4>
            </div>

        </OverlayTrigger>
        <div className="d-flex justify-content-center my-3">
            <InsuranceProgressBar
                choice={choice}
                mainGameHistory={[]}
                size='l'
                direction='horizontal'
                showWonCoins={true}
                onTapped={null} />
        </div>
        <h3 className="text-success my-3 text-center">PAYS <u>{numberWithCommas(calculatePayout(parseValue(choice).number, amount))}</u> SOL</h3>
        {/* <StreakButtons choice={choice} setChoice={setChoice} /> */}
        {/* <h3 className="mb-1 text-center">FOR</h3> */}
        {
            !whaleMode && <NormalButtons price={amount} setPrice={setAmount} />
        }
        {
            whaleMode && <WhaleButtons price={amount} setPrice={setAmount} />
        }

        <div className="d-flex justify-content-between mt-2 mb-1">
            <Button className={`${!whaleMode ? 'd-none' : ''}`} size="xs" variant="link" onClick={toggleWhaleMode} disabled={!whaleMode}>
                <span className="fa-solid fa-chevron-left" />
            </Button>
            <hr className="w-100" />
            <Button className={`${whaleMode ? 'd-none' : ''}`} size="xs" variant="link" onClick={toggleWhaleMode} disabled={whaleMode}>
                <span className="fa-solid fa-chevron-right" />
            </Button>
        </div>
        <img
            onClick={onSubmit}
            className="cursor-pointer double-or-nothing-button mb-1"
            src={Images.ACTIVATE_INSURANCE}
            alt="activate insurance"
            width="100%"
            height="100%"
        />

    </>);
}

// const StreakButtons = ({ choice, setChoice }) => {
//     return (
//         <div className="row mb-1">
//             <div className="col-6">
//                 <ImageToggleButton
//                     name="L2"
//                     buttonImageSrc={Images.STREAK2}
//                     isSelected={choice === "L2"}
//                     onSelect={() => setChoice("L2")}
//                 />
//             </div>
//             <div className="col-6">
//                 <ImageToggleButton
//                     name="L3"
//                     buttonImageSrc={Images.FLIP3}
//                     isSelected={choice === "L3"}
//                     onSelect={() => setChoice("L3")}
//                 />
//             </div>
//         </div>)
// };

const NormalButtons = ({ price, setPrice }) => {
    return (
        <>
            <div className="row mb-1">
                <div className="col-4">
                    <ImageToggleButton
                        name="0.05 SOL"
                        buttonImageSrc={constants.BUTTONS.BabyButton}
                        isSelected={price === 0.05}
                        onSelect={() => setPrice(0.05)}
                    />
                </div>
                <div className="col-4">
                    <ImageToggleButton
                        name="0.1 SOL"
                        buttonImageSrc={constants.BUTTONS.PtOneSol}
                        isSelected={price === 0.1}
                        onSelect={() => setPrice(0.1)}
                    />
                </div>
                <div className="col-4">
                    <ImageToggleButton
                        name="0.25 SOL"
                        buttonImageSrc={constants.BUTTONS.Pt25Sol}
                        isSelected={price === 0.25}
                        onSelect={() => setPrice(0.25)}
                    />
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-4">
                    <ImageToggleButton
                        name="0.5 SOL"
                        buttonImageSrc={constants.BUTTONS.PtFiveSol}
                        isSelected={price === 0.5}
                        onSelect={() => setPrice(0.5)}
                    />
                </div>
                <div className="col-4">
                    <ImageToggleButton
                        name="1 SOL"
                        buttonImageSrc={constants.BUTTONS.OneSol}
                        isSelected={price === 1}
                        onSelect={() => setPrice(1)}
                    />
                </div>
                <div className="col-4">
                    <ImageToggleButton
                        name="2 SOL"
                        buttonImageSrc={constants.BUTTONS.TwoSol}
                        isSelected={price === 2}
                        onSelect={() => setPrice(2)}
                    />
                </div>
            </div>
        </>
    )
}

const WhaleButtons = ({ price, setPrice }) => {
    return (
        <>
            <div className="row mb-1">
                <div className="col-4">
                    <ImageToggleButton
                        name="3 SOL"
                        buttonImageSrc={Images.SOL3}
                        isSelected={price === 3}
                        onSelect={() => setPrice(3)}
                    />
                </div>
                <div className="col-4">
                    <ImageToggleButton
                        name="4 SOL"
                        buttonImageSrc={Images.SOL4}
                        isSelected={price === 4}
                        onSelect={() => setPrice(4)}
                    />
                </div>
                <div className="col-4">
                    <ImageToggleButton
                        name="5 SOL"
                        buttonImageSrc={Images.SOL5}
                        isSelected={price === 5}
                        onSelect={() => setPrice(5)}
                    />
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-4">
                    <ImageToggleButton
                        name="6 SOL"
                        buttonImageSrc={Images.SOL6}
                        isSelected={price === 6}
                        onSelect={() => setPrice(6)}
                    />
                </div>
                <div className="col-4">
                    <ImageToggleButton
                        name="8 SOL"
                        buttonImageSrc={Images.SOL8}
                        isSelected={price === 8}
                        onSelect={() => setPrice(8)}
                    />
                </div>
                <div className="col-4">
                    <ImageToggleButton
                        name="10 SOL"
                        buttonImageSrc={Images.SOL10}
                        isSelected={price === 10}
                        onSelect={() => setPrice(10)}
                    />
                </div>
            </div>
        </>
    )
}

export default InsuranceSelectionMenu;