export const Tabs = {
    HEADTAILS: 'headtails',
    WINLOSS: 'winloss',
};

export const NavTabs = {
    SIDE_BETS: 'sidebets',
    HISTORY: 'history',
};

export const DegenCoinFlipSideBets = {
    H2: 'H2',
    H3: 'H3',
    H5: 'H5',
    H7: 'H7',
    T2: 'T2',
    T3: 'T3',
    T5: 'T5',
    T7: 'T7',
    W2: 'W2',
    W3: 'W3',
    W5: 'W5',
    W7: 'W7',
    L2: 'L2',
    L3: 'L3',
    L5: 'L5',
    L7: 'L7',
};

export const HeadTailsChoices = [
    { value: DegenCoinFlipSideBets.H2, name: "HEADS", multiplier: "2" },
    { value: DegenCoinFlipSideBets.H3, name: "HEADS", multiplier: "3" },
    // { value: DegenCoinFlipSideBets.H5, name: "HEADS", multiplier: "5" },
    // { value: DegenCoinFlipSideBets.H7, name: "HEADS", multiplier: "10" },
    { value: DegenCoinFlipSideBets.T2, name: "TAILS", multiplier: "2" },
    { value: DegenCoinFlipSideBets.T3, name: "TAILS", multiplier: "3" },
    // { value: DegenCoinFlipSideBets.T5, name: "TAILS", multiplier: "5" },
    // { value: DegenCoinFlipSideBets.T7, name: "TAILS", multiplier: "10" },
];

export const WinLossChoices = [
    { value: DegenCoinFlipSideBets.W2, name: "WIN", multiplier: "2" },
    { value: DegenCoinFlipSideBets.W3, name: "WIN", multiplier: "3" },
    // { value: DegenCoinFlipSideBets.W5, name: "WIN", multiplier: "5" },
    // { value: DegenCoinFlipSideBets.W7, name: "WIN", multiplier: "7" },
    { value: DegenCoinFlipSideBets.L2, name: "LOSS", multiplier: "2" },
    { value: DegenCoinFlipSideBets.L3, name: "LOSS", multiplier: "3" },
    // { value: DegenCoinFlipSideBets.L5, name: "LOSS", multiplier: "5" },
    // { value: DegenCoinFlipSideBets.L7, name: "LOSS", multiplier: "7" },
];
